<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group mt-3 p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group icon-input mb-0">
                            <i class="ti-search font-xs text-grey-400"></i>
                            <select @change="setCourse()" v-model="slug" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100">
                                <option value="" selected disabled>Select Course</option>
                                <option v-for="(item, index) in courses" :key="index" :value="item.slug">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden px-3">
                        <div class="card-body">
                            <FullCalendar :options="calendarOptions" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import axios from 'axios'

export default {
    name: "Calendar",
    components:{
        FullCalendar
    },
    data(){
        return {
            courses: [],
            slug: '',
            calendarOptions: {
                plugins: [ dayGridPlugin],
                initialView: 'dayGridMonth',
                events: [],
            }
        }
    },
    created(){
        this.getCourse()
        this.getCalendar()
    },
    methods: {
        setCourse(){
            this.calendarOptions.events = []
            this.getCalendar()
        },
        async getCourse(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/simple-mycourse`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.courses = res.data.data
            })
        },
        async getCalendar(){
            if(this.slug) {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/calendar?slug=${this.slug}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.calendarOptions.events = res.data.data
                })
            } else {
                this.calendarOptions.events = []
            }
        },
    }
}
</script>